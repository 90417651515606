import { createContext, useState, useContext, useEffect } from "react";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const searchResults = localStorage.getItem("searchResults");
    const searchQuery = localStorage.getItem("searchQuery");

    if (!!searchResults) {
      const parsedSearchResults = JSON.parse(searchResults);
      const parsedSearchQuery = JSON.parse(searchQuery);

      setResults(parsedSearchResults);
      setQuery(parsedSearchQuery);
      setIsLoading(false);
    }
  }, []);

  return (
    <SearchContext.Provider
      value={{ results, setResults, query, setQuery, isLoading, setIsLoading }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
