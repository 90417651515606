import { Fira_Sans, Merriweather } from "next/font/google";
import { useRouter } from "next/router";
import Link from "next/link";
import { useEffect, useState } from "react";
import { PrismicProvider } from "@prismicio/react";
import { PrismicPreview } from "@prismicio/next";

import type { AppProps } from "next/app";

import { SearchProvider } from "../context/SearchContext";
import sm from "../../slicemachine.config.json";
import * as gtag from "../lib/gtag";
import { GoogleAnalytics } from "../components/GoogleAnalytics";

import "../styles/globals.css";

const repoName = sm.repositoryName;

const fira = Fira_Sans({
  weight: ["600", "700", "800"],
  subsets: ["latin"],
});

const merriweather = Merriweather({
  weight: ["300", "400"],
  subsets: ["latin"],
});

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [isProdMode, setIsProdMode] = useState(false);

  useEffect(() => {
    setIsProdMode(process.env.NODE_ENV === "production" ? true : false);

    if (isProdMode) {
      const handleRouteChange = (url: string) => {
        gtag.pageview(url);
      };
      router.events.on("routeChangeComplete", handleRouteChange);
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
  }, [router, isProdMode]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // Clear local storage only if navigating away from the search results page
      if (!url.includes("/pesquisa")) {
        localStorage.removeItem("searchResults");
        localStorage.removeItem("searchQuery");
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  return (
    <>
      <style jsx global>{`
        :root {
          --headingFont: ${fira.style.fontFamily};
          --generalFont: ${merriweather.style.fontFamily};
        }
      `}</style>
      <PrismicProvider internalLinkComponent={(props) => <Link {...props} />}>
        <PrismicPreview repositoryName={repoName}>
          <SearchProvider>
            <Component {...pageProps} />
          </SearchProvider>
          {isProdMode && <GoogleAnalytics />}
        </PrismicPreview>
      </PrismicProvider>
    </>
  );
}
